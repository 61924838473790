import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
  flexrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'middle',
    width: '100%',
  }
}))

const FlexRow = props => {
  const {
    className,
    children,
    ...rest
  } = props
  const classes = useStyles()

  return (
    <div
      className={`${classes.flexrow} ${className}`}
      {...rest}
    >
      { children }
    </div>
  )
}

export default FlexRow

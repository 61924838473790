import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'

import useFormStyles from 'components/form/styles'


const TextInput = props => {
  const {
    fullWidth=true,
    label,
    marginLeft,
    marginRight,
    squaredLeft,
    squaredRight,
    variant='outlined',
    ...rest
  } = props
  if (fullWidth) {
    rest.fullWidth = true
  }

  const classes = useFormStyles()

  return (
    <TextField
      className={classNames(
        marginLeft && classes.marginLeft,
        marginRight && classes.marginRight,
        squaredLeft && classes.squaredLeft,
        squaredRight && classes.squaredRight,
      )}
      label={label}
      squaredLeft={true}
      variant={variant}
      {...rest}
    />
  )
}

export default TextInput

import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FlashOnIcon from '@material-ui/icons/FlashOn'

import axios from 'axios'

import Button from 'components/form/Button'
import FlexRow from 'components/layout/FlexRow'
import JsonViewer from 'components/form/JsonViewer'
import Select from 'components/form/Select'
import Table from 'components/layout/Table'
import Tabs from 'components/layout/Tabs'
import TextInput from 'components/form/TextInput'

import { RequestContext } from 'context/RequestContext.js'


const axiosMethods = {
  'GET': axios.get,
  'PUT': axios.put,
  'POST': axios.post,
  'DELETE': axios.delete,
}

const useStyles = makeStyles((theme) => ({
  select: {
    width: '150px',
    marginRight: `${theme.spacing(2)}px`,
  }
}))

function ExecutionTab() {
  const classes = useStyles()

  const {
    requestHistory,
    requestListPath,
    requestMethod,
    requestResponse,
    requestURL,
    setRequestHistory,
    setRequestListPath,
    setRequestMethod,
    setRequestResponse,
    setRequestURL,
  } = useContext(RequestContext)

  const executeRequest = (method, url) => {
    axiosMethods?.[method](url).then(response => {
      setRequestResponse(response)
      requestHistory.push({
        method: method,
        url: url,
      })
      setRequestHistory(requestHistory)
      console.log('response', response)
    })
  }

  let columns = []
  let data = {}
  if (requestResponse) {
    const path = requestListPath.split('.')
    data = requestResponse.data
    for (let key in path) {
      data = data?.[path[key]]
    }
    if (data && data.length > 0) {
      columns = Object.keys(data[0])
    }
  }

  return (
    <div>
      <FlexRow>
        <Select
          backgroundColors={{
            'GET': '#61affe',
            'PUT': '#fca130',
            'POST': '#49cc90',
            'DELETE': '#f93e3e',
          }}
          onChange={e => setRequestMethod(e.target.value)}
          squaredRight={true}
          options={[
            {
              label: 'GET',
              value: 'GET',
            },
            {
              label: 'PUT',
              value: 'PUT',
            },
            {
              label: 'POST',
              value: 'POST',
            },
            {
              label: 'DELETE',
              value: 'DELETE',
            },
          ]}
          value={requestMethod}
          width="130"
        />
        <TextInput
          label="URL"
          onChange={e => setRequestURL(e.target.value)}
          squaredLeft={true}
        />
        <Button
          onClick={() => executeRequest(requestMethod, requestURL)}
          variant="outlined"
        >
          <FlashOnIcon />
        </Button>
      </FlexRow>
      <div>
        <h3>Response</h3>
        {requestResponse &&
          <div>
            <Tabs
              tabs={[
                {
                  label: 'Raw',
                  content: (
                    <JsonViewer
                      src={requestResponse.data}
                    />
                  ),
                },
                {
                  label: 'Table',
                  content: (
                    <div>
                      {!data &&
                        <div
                          style={{
                            color: '#ff5f92',
                            margin: '14px 0',
                            fontStyle: 'italic',
                          }}
                        >
                          No list data found at "{requestListPath}" - try adjusting your table path below: 
                        </div>
                      }
                      {data &&
                        <Table
                          columns={columns}
                          data={data}
                        />
                      }
                    </div>
                  ),
                },
              ]}
            />
          <TextInput
            label="Table path (e.g. 'results.list')"
            onChange={e => setRequestListPath(e.target.value)}
            style={{marginTop: 15}}
            value={requestListPath}
          />
          </div>
        }
      </div>
    </div>
  )
}

export default ExecutionTab

import { createContext, useState } from "react"


const SettingsContext = createContext()

const SettingsContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true)

  return (
    <SettingsContext.Provider value={{
      darkMode,
      setDarkMode
    }}>
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsContext, SettingsContextProvider }

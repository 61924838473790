import React, { useContext } from "react";

import { default as MUIButton } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { SettingsContext, SettingsContextProvider } from 'context/SettingsContext'


const useStyles = makeStyles((theme) => ({
  button: {
    margin: `0 0 0 ${theme.spacing(1)}px`,
    padding: `${theme.spacing(1.3)}px`,
  },
  darkMode: {
    backgroundColor: '#ff5f92',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00d0bd',
    },
  },
  lightMode: {
    backgroundColor: '#ff5f92',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00d0bd',
    },
  },
}))

const Button = props => {
  const {
    children,
    variant='outlined',
    ...rest
  } = props

  const {
    darkMode,
    setDarkMode
  } = useContext(SettingsContext)
  const classes = useStyles()
  const themeClass = darkMode ? classes.darkMode : classes.lightMode

  return (
    <MUIButton
      className={`${classes.button} ${themeClass}`}
      variant={variant}
      {...rest}
    >
      { children }
    </MUIButton>
  )
}

export default Button

import { useContext } from 'react'

import {
  makeStyles,
} from '@material-ui/core/styles'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import {default as MoonIcon} from '@material-ui/icons/Brightness2'
import {default as SunIcon} from '@material-ui/icons/WbSunny'
import {default as TextSizeIcon} from '@material-ui/icons/TextFields';

import { SettingsContext, SettingsContextProvider } from 'context/SettingsContext'

const useStyles = makeStyles((theme) => ({
  toggle: {
    position: 'fixed',
    bottom: `${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`,
  }
}))


const ThemeToggle = (props) => {
  const classes = useStyles()
  const {
    darkMode,
    setDarkMode
  } = useContext(SettingsContext)

  return (
      <div
        className={classes.toggle}
        onClick={() => setDarkMode(darkMode==false)}
      >
        <ToggleButtonGroup
          value={darkMode}>
          onChange={
            () => setDarkMode(darkMode==false)
          }
        >
          <ToggleButton value={true}>
            <MoonIcon />
          </ToggleButton>
          <ToggleButton value={false}>
            <SunIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
  )
}

export default ThemeToggle;

import { useContext } from "react";
import ReactJson from 'react-json-view'
import { SettingsContext, SettingsContextProvider } from 'context/SettingsContext'


const JsonViewer = props => {
  const {
    src,
    ...rest
  } = props
  const {
    darkMode,
    setDarkMode
  } = useContext(SettingsContext)

  return (
    <ReactJson
      src={src}
      theme={darkMode ? 'ocean' : 'rjv-default'}
    />
  )
}

export default JsonViewer

import { useContext } from 'react'

import { RequestContext } from 'context/RequestContext.js'


const RequestsTab = props => {
  const {
    requestHistory,
  } = useContext(RequestContext)

  return (
    <div>
      <h3>Request History</h3>
      <ul>
        {requestHistory.map(request => (
          <li>
            {request.method} - {request.url}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RequestsTab

import React, { useState, useContext } from 'react'
import { Helmet } from "react-helmet"

import {
  createTheme,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles'

import { RequestContext, RequestContextProvider } from 'context/RequestContext.js'
import { SettingsContext, SettingsContextProvider } from 'context/SettingsContext.js'

import Tabs from 'components/layout/Tabs'
import ExecutionTab from 'components/tabs/ExecutionTab'
import RequestsTab from 'components/tabs/RequestsTab'
import ThemeToggle from 'components/ThemeToggle'

const rootThemeParams = {
  typography: {
    fontSize: 11,
  },
  spacing: 7,
  overrides: {
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)"
      }
    },
    MuiOutlinedInput: {
      root: {
        '&&& $input': {
          padding: '12px',
        }
      }
    }
  }
}

const ThemeWrapper = props => {
  const { darkMode, setDarkMode } = useContext(SettingsContext)
  const themeParams = darkMode ? {
    ...rootThemeParams,
    palette: {
      type: 'dark',
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#ffffff',
      },
      neutral: {
        main: '#ffffff',
      },
    }
  } : {
    ...rootThemeParams,
    palette: {
      type: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#000000',
      },
      neutral: {
        main: '#000000',
      }
    }
  }
  const theme = createTheme(themeParams)

  const appStyles = {
    theme: {
      width: `calc( 100vw - ${theme.spacing(4)}px)`,
      padding: `${theme.spacing(2)}px`,
    }
  }

  const useStyles = makeStyles((theme) => (appStyles))
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link
          rel="stylesheet"
          href={darkMode ? 'style/dark.css' : 'style/light.css'}
        />
      </Helmet>
      <div
        className={classes.theme}
        style={{
          color: themeParams.palette.primary.main,
        }}
      >
        {props.children}
      </div>
    </ThemeProvider>
  )
}

function App() {
  return (
    <SettingsContextProvider>
      <RequestContextProvider>
        <ThemeWrapper>
          <Tabs
            tabs={[
              {
                label: 'Execution',
                content: (
                  <ExecutionTab />
                ),
              },
              {
                label: 'Requests',
                content: <RequestsTab />,
              }
            ]}
          />
          
          <ThemeToggle />
        </ThemeWrapper>
      </RequestContextProvider>
    </SettingsContextProvider>
  )
}

export default App

import { createContext, useState } from "react"


const RequestContext = createContext()

const RequestContextProvider = ({ children }) => {
  const [requestHistory, setRequestHistory] = useState([])
  const [requestListPath, setRequestListPath] = useState('results')
  const [requestMethod, setRequestMethod] = useState('GET')
  const [requestResponse, setRequestResponse] = useState()
  const [requestURL, setRequestURL] = useState('')

  return (
    <RequestContext.Provider value={{
      requestHistory,
      requestListPath,
      requestMethod,
      requestResponse,
      requestURL,
      setRequestHistory,
      setRequestListPath,
      setRequestMethod,
      setRequestURL,
      setRequestResponse,
    }}>
      {children}
    </RequestContext.Provider>
  )
}

export { RequestContext, RequestContextProvider }

import { useState, useContext } from "react"
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { default as MUITabs } from '@material-ui/core/Tabs'
import { default as MUITab } from '@material-ui/core/Tab'

import { SettingsContext, SettingsContextProvider } from 'context/SettingsContext'


const useStyles = makeStyles((theme) => ({
    active: {
      background: 'transparent',
      borderBottom: 'none',
      borderTopLeftRadius: `${theme.spacing(1)}px`,
      borderTopRightRadius: `${theme.spacing(1)}px`,
      height: '29px',
      top: '1px',
    },
    activeDark: {
      border: '1px solid #475766',
    },
    activeLight: {
      border: '1px solid #c4c4c4',
    },
    dark: {
      color: '#ffffff',
    },
    tabs: {
      height: '30px',
      minHeight: 'auto',
      marginBottom: `${theme.spacing(1)}px`,
    },
    tabsDark: {
      borderBottom: '1px solid #475766',
    },
    tabsLight: {
      borderBottom: '1px solid #c4c4c4',
    },
    tab: {
      height: '30px',
      minHeight: 'auto',
      padding: '0px',
      textTransform: 'inherit',
      minWidth: '120px',
    },
    tabActive: {
      fontWeight: 'bold',
    }
  }))

const Tabs = props => {
  const {
    tabs,
    value,
  } = props

  const {
    darkMode,
    setDarkMode
  } = useContext(SettingsContext)
  const [ tabValue, setTabValue ] = useState(0)

  
  const classes = useStyles()

  return (
    <div>
      <MUITabs
        onChange={(e, v) => setTabValue(v)}
        className={classNames(
          darkMode && classes.dark,
        )}
        classes={{
          indicator: classNames(
            classes.active,
            classes.tab,
            darkMode && classes.activeDark,
            !darkMode && classes.activeLight,
          ),
          root: classNames(
            classes.tabs,
            darkMode && classes.tabsDark,
            !darkMode && classes.tabsLight,
          ),
        }}
        value={tabValue}
      >
        {tabs.map((tab, index) => (
          <MUITab
            className={classNames(
              classes.tab,
              index === tabValue && classes.tabActive,
            )}
            label={tab.label}
            value={index}
          />
        ))}
      </MUITabs>
      {tabs.map((tab, index) => 
        tabValue === index && <div>{tab.content}</div>
      )}
    </div>
  )
}

export default Tabs

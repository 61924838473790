import { makeStyles } from '@material-ui/core/styles'


const useFormStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: `${theme.spacing(2)}px`,
  },
  marginRight: {
    marginRight: `${theme.spacing(2)}px`,
  },
  padding: {
    margin: 0,
    padding: 0,
  },
  squaredLeft: {
    'position': 'relative',
    'left': '-1px',
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderLeftWidth: '0px',
    }
  },
  squaredRight: {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    }
  },
}))

export default useFormStyles

import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { default as MUISelect } from '@material-ui/core/Select'

import useFormStyles from 'components/form/styles'


const Select = props => {
  const {
    backgroundColors,
    marginLeft,
    marginRight,
    onChange,
    options,
    squaredLeft,
    squaredRight,
    value,
    width,
  } = props

  const classes = useFormStyles()

  return (
    <FormControl
      className={classNames(
        marginLeft && classes.marginLeft,
        marginRight && classes.marginRight,
        squaredRight && classes.squaredRight,
      )}
      variant="outlined"
      style={{
        width: `${width}px`,
      }}
    >
      <MUISelect
        onChange={onChange}
        value={value}
        style={{
          color: backgroundColors?.[value],
          fontWeight: 'bold',
        }}
      >
        {options.map(option => (
          <MenuItem
            color={backgroundColors?.[option.value]}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  )
}

export default Select
